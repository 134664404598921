import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "dropdown"]

  toggleMenu() {
    this.menuTarget.classList.toggle("hidden")
    if (this.menuTarget.classList.contains("hidden")) {
      this.dropdownTarget.classList.add("hidden")
    }
  }

  toggleDropdown(event) {
    event.stopPropagation()
    this.dropdownTarget.classList.toggle("hidden")
    
    if (!this.dropdownTarget.classList.contains("hidden")) {
      document.addEventListener("click", this.closeDropdownOnClickOutside)
    } else {
      document.removeEventListener("click", this.closeDropdownOnClickOutside)
    }
  }

  closeDropdownOnClickOutside = (event) => {
    if (!this.element.contains(event.target)) {
      this.dropdownTarget.classList.add("hidden")
      document.removeEventListener("click", this.closeDropdownOnClickOutside)
    }
  }
}
