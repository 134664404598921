// app/javascript/controllers/highlight_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]

  connect() {
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      rootMargin: "0px 0px -80% 0px",
      threshold: [0, 0.1, 0.9, 1]
    });

    this.linkTargets.forEach(link => {
      const targetId = link.getAttribute("href")?.replace("#", "");
      if (targetId) {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          this.observer.observe(targetElement);
        }
      }
    });
  }

  handleIntersect(entries) {
    const visibleEntries = entries.filter(entry => entry.isIntersecting);

    if (visibleEntries.length > 0) {
      const lastVisibleEntry = visibleEntries[visibleEntries.length - 1];
      const activeLink = this.linkTargets.find(link => link.getAttribute("href")?.replace("#", "") === lastVisibleEntry.target.id);

      this.linkTargets.forEach(link => {
        if (link === activeLink) {
          link.classList.add("font-bold", "text-violet-600");
        } else {
          link.classList.remove("font-bold", "text-violet-600");
        }
      });
    }
  }

  disconnect() {
    this.observer.disconnect();
  }
}
